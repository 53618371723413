@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

body * {
  font-family: 'Noto Sans KR', sans-serif;
}
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/*html, html body{*/
/*  height: 100%;*/
/*}*/

/* .menu-item, .menu-item * {
    font-weight: bold !important;
} */

.read-only {
    cursor: not-allowed;
}

.field-container {
    margin-top: 1rem;
}

.summernote h1 {
    font-size: xx-large !important;
    font-weight: bolder !important;
}

.summernote h2 {
    font-size: x-large !important;
    font-weight: bolder !important;
}

.summernote h3 {
    font-size: larger !important;
    font-weight: bolder !important;
}

.summernote h4 {
    font-size: large !important;
    font-weight: bolder !important;
}

.summernote h5 {
    font-size: medium !important;
    font-weight: bolder !important;
}

.summernote h6 {
    font-size: medium !important;
    font-weight: bold !important;
}

/*.card {*/
/*  animation: fadein 1s;*/
/*  -moz-animation: fadein 1s; !* Firefox *!*/
/*  -webkit-animation: fadein 1s; !* Safari and Chrome *!*/
/*  -o-animation: fadein 1s; !* Opera *!*/
/*}*/

.fade-in-box-1 {
  animation: fadein 1s;
  -moz-animation: fadein 1s; /* Firefox */
  -webkit-animation: fadein 1s; /* Safari and Chrome */
  -o-animation: fadein 1s; /* Opera */
}
.fade-in-box-2 {
  animation: fadein 2s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -o-animation: fadein 2s; /* Opera */
}

.slide-in-box-1 {
  animation: slidein 1s;
  -moz-animation: slidein 1s; /* Firefox */
  -webkit-animation: slidein 1s; /* Safari and Chrome */
  -o-animation: slidein 1s; /* Opera */
}


@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  /*main {*/
  /*  overflow: unset;*/
  /*}*/
  html {
    overflow-y: auto;
  }
}

html {
  overflow-y: hidden;
}

/* scrollbar */
*{
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  width: 8px; /* 스크롤바의 너비 */
  margin-right: 8px;
  margin-left: 8px;
  margin-bottom: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  height: 30%; /* 스크롤바의 길이 */
  background: rgba(190, 190, 190, 0.62); /* 스크롤바의 색상 */
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background: rgb(255, 255, 255); /*스크롤바 뒷 배경 색상*/
}

.vertical-line-on-left {
  border-left: 1px solid #ffffff;
  padding-left: 2rem;
  padding-right: 2rem;
}
.vertical-line-on-right {
  border-right: 1px solid #ffffff;
  padding-left: 2rem;
  padding-right: 2rem;
}
/*mobile*/
@media (max-width: 768px) {
  .vertical-line-on-left {
    border-left: none;
  }
  .vertical-line-on-right {
    border-right: none;
  }
}
.padding-auto {
  padding: 0 calc(1rem + 1vw);
}

.height-auto {
  min-height: calc(100vh - 16rem);
}

@media screen and (min-width: 1200px) {
  .padding-auto {
    padding: 0 calc(50% - 600px);
  }
}

/*reset list style*/
ul, li {
  list-style: none;
  content: none;
}
.is-full {
  width: 100%;
  height: 100%;
}
.is-fullwidth {
  width: 100%;
}
.is-fullheight {
  height: 100%;
}

